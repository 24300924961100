window.kernel = {
    init: function (options) {
        function actualInit() {
            if (location.protocol !== 'https:') {
                throw new Error('Kernel Booking must only be embedded on sites with HTTPS enabled')
            }

            if (options.category === undefined) {
                options.category = ''
            }
            if (options.el === undefined) {
                options.el = 'body'
            }
            if (options.page === undefined) {
                options.page = 'search'
            }

            let sub = 'account'
            if (options.develop) {
                sub = 'develop'
            } else if (options.demo) {
                sub = 'demo'
            }

            const rnd = Math.floor((Math.random() * 1000000000) + 1)

            const container = document.createElement('div')
            container.setAttribute('id', 'kernel_' + rnd)
            document.querySelector(options.el).appendChild(container)

            let hash = window.location.hash.replace('#', '')
            if (/^kb-/.test(hash) && (options.page === 'search' || options.page === 'detail')) {
                let category = null
                const query = hash.split(':')
                if (query[1]) {
                    category = query[1]
                }
                hash = '/' + query[0].replace('kb-', '').split('-').join('/') + (category !== null ? ('?category=' + category) : '')

            } else {
                hash = null
            }

            let url = 'https://' + sub + '.kernelbooking.co.uk/' + options.client + (hash !== null ? hash : '')

            const params = window.location.search.substring(1).split('&').reduce((p, c) => {
                const pair = c.split('=')
                p[pair[0]] = pair[1]
                return p
            }, {})

            if (hash === null) {
                switch (options.page) {
                    case 'search':
                        url += '/rooms'
                        if (options.category) {
                            url += '?category=' + options.category
                        }
                        break

                    case 'detail':
                        url += '/rooms/' + options.detailid
                        break

                    case 'calendar':
                        url += '/rooms/' + options.detailid + '/calendar'
                        break

                    case 'view-booking':
                        url += '/bookings'
                        if (params['bookingid'] !== undefined && (params.surname !== undefined || params.token !== undefined)) {
                            url += '/' + params['bookingid']
                            if (params.token !== undefined) {
                                url += '/amendemail?token=' + params.token
                            } else {
                                url += '?surname=' + params.surname
                            }
                        }
                        break
                }
            }

            const frame = document.createElement('iframe')
            frame.setAttribute('width', '100%')
            frame.setAttribute('frameborder', '0')
            frame.setAttribute('src', url)
            container.appendChild(frame)

            window.addEventListener('message', e => {
                const message = JSON.parse(e.data)
                switch (message.type) {
                    case 'height':
                        frame.setAttribute('height', message.data+'px')
                        break

                    case 'load':
                        if (options.page === 'search' || options.page === 'detail') {
                            window.location.hash = message.data
                        }
                        break
                }
            });
        }

        if (document.readyState === 'complete' || document.readyState === 'loaded' || document.readyState === 'interactive') {
            actualInit()
        } else {
            document.addEventListener('DOMContentLoaded', () => {
                actualInit()
            })
        }
    }
}

//
// Usage:
//
// window.kernel.init({
//     el: '#test',            // optional but recommended, defaults to 'body'
//     client: 'kernel-acres', // required
//     page: 'search',         // optional but recommended, defaults to 'search', possible options are 'search', 'view-booking' and 'detail'
//     detailid: 1,            // required if page is 'detail' otherwise does nothing, the roomid to show on the detail page
//     category: 1,            // optional, defaults to null, show only rooms of this category id when on search page
//     develop: false,         // optional, for internal use only
//     demo: false             // optional, for internal use only
// });
//
